import React from "react"
import Layout from "../components/layout"
import { Seo } from "../components/Seo"

const privacy = () => {
  return (
    <Layout>
      <div className="ui-body-container products">
        <h1 className="body-title product-title">Privacy Policy</h1>
        <div className="spacing-divider--extra-small"></div>
        <div className="ui-body__text-image products">
          <div className="ui-body__text-image--left product-content">
            <h1 className="body-title">Intelligence Analytics Limited</h1>

            <p>
              At IA — one of our main priorities is the privacy of our visitors.
              This Privacy Policy document contains the types of information
              collected and recorded by IA and how we use it.
            </p>

            <p>
              If you have additional questions or require more information about
              our Privacy Policy, do not hesitate to contact us.
            </p>

            <p>
              This Privacy Policy applies only to our online activities and is
              valid for visitors to our website with regards to the information
              that they shared and/or collect in IA. This policy is not
              applicable to any information collected offline or via channels
              other than this website.
            </p>

            <div className="spacing-divider--extra-small"></div>

            <h1 className="body-title">Consent</h1>

            <p>
              By using our website, you hereby consent to our Privacy Policy and
              agree to its terms.
            </p>

            <div className="spacing-divider--extra-small"></div>

            <h1 className="body-title">Information We Collect</h1>

            <p>
              The personal information that you are asked to provide, and the
              reasons why you are asked to provide it, will be made clear to you
              at the point we ask you to provide your personal information.
            </p>

            <p>
              If you contact us directly, we may receive additional information
              about you such as your name, email address, phone number, the
              contents of the message and/or attachments you may send us, and
              any other information you may choose to provide.
            </p>

            <p>
              When you register for an Account, we may ask for your contact
              information, including items such as name, company name, address,
              email address, and telephone number.
            </p>

            <div className="spacing-divider--extra-small"></div>

            <h1 className="body-title">How We Use Your Information</h1>

            <p>
              We use the information we collect in various ways, including to:
            </p>

            <p>
              • Provide, operate and maintain our website • Improve,
              personalize, and expand our website • Understand and analyze how
              you use our website • Develop new products, services, features,
              and functionality • Communicate with you, either directly or
              through one of our partners, including for customer service, to
              provide you with updates and other information relating to the
              website, and for marketing and promotional purposes • Send you
              emails • Find and prevent fraud
            </p>

            <div className="spacing-divider--extra-small"></div>

            <h1 className="body-title">Log Files</h1>

            <p>
              IA follows a standard procedure of using log files. These files
              log visitors when they visit websites. All hosting companies do
              this and are a part of hosting services' analytics. The
              information collected by log files includes internet protocol (IP)
              addresses, browser type, Internet Service Provider (ISP), date and
              time stamp, referring/exit pages, and possibly the number of
              clicks. These are not linked to any information that is personally
              identifiable. The purpose of the information is for analyzing
              trends, administering the site, tracking users' movement on the
              website, and gathering demographic information.
            </p>

            <div className="spacing-divider--extra-small"></div>

            <h1 className="body-title">Cookies & Web Beacons</h1>

            <p>
              Like any other website, IA uses 'cookies'. These cookies are used
              to handle bot traffic and do not collect or track users at all.
              They only exist to qualify if you are a bot or not.
            </p>

            <div className="spacing-divider--extra-small"></div>

            <h1 class="body-title">
              CCPA and Privacy Rights (Do Not Sell My Personal Information)
            </h1>

            <p>
              Under the CCPA, among other rights, California consumers have the
              right to:
            </p>

            <p>
              Request that a business that collects a consumer's personal data
              disclose the categories and specific pieces of personal data that
              a business has collected about consumers.
            </p>

            <p>
              Request that a business deletes any personal data about the
              consumer that a business has collected.
            </p>

            <p>
              If you make a request, we have one month to respond. If you would
              like to exercise any of these rights, please contact us.
            </p>

            <div className="spacing-divider--extra-small"></div>

            <h1 className="body-title">GDPR Data Protection Rights</h1>

            <p>
              We would like to make sure you are fully aware of all of your data
              protection rights. Every user is entitled to the following:
            </p>

            <p>
              The right to access – You have the right to request copies of your
              personal data. We may charge you a small fee for this service.
            </p>

            <p>
              The right to rectification – You have the right to request that we
              correct any information you believe is inaccurate. You also have
              the right to request that we complete the information you believe
              is incomplete.
            </p>

            <p>
              The right to erasure – You have the right to request that we erase
              your personal data, under certain conditions.
            </p>

            <p>
              The right to restrict processing – You have the right to request
              that we restrict the processing of your personal data, under
              certain conditions.
            </p>

            <p>
              The right to object to processing – Under certain conditions, you
              have the right to object to our processing of your personal data.
            </p>

            <p>
              The right to data portability – You have the right to request that
              we transfer the data that we have collected to another
              organization, or directly to you, under certain conditions.
            </p>

            <p>
              If you make a request, we have one month to respond. If you would
              like to exercise any of these rights, please contact us.
            </p>

            <div className="spacing-divider--extra-small"></div>

            <h1 className="body-title">Children's Information</h1>

            <p>
              Another part of our priority is adding protection for children
              while using the internet. We encourage parents and guardians to
              observe, participate in, and/or monitor and guide their online
              activity.
            </p>

            <p>
              IA does not knowingly collect any Personal Identifiable
              Information from children under the age of 13. If you think that
              your child provided this kind of information on our website, we
              strongly encourage you to contact us immediately and we will do
              our best efforts to promptly remove such information from our
              records.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default privacy

export const Head = () => <Seo />
